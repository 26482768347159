import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'
import api from './api'
import 'amfe-flexible';
import {NumberKeyboard} from 'vant';
import './assets/imfycIcon/iconfont.css';
import './assets/imfycIcon/iconfont.js';
//
Vue.use(NumberKeyboard);
Vue.use(VueCookies)
Vue.prototype.$api = api;
//
Vue.config.productionTip = false
//
const nu = navigator.userAgent.toLowerCase()
Vue.prototype.$client = Vue.client = {
    WEIXIN: nu.indexOf('micromessenger') > -1,
    ALIPAY: nu.indexOf('alipayclient') > -1,
}
//
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
