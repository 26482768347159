import axios from 'axios';
import {Toast} from "vant";
import CryptoJS from "../utils/des";
import {isEmpty} from "../utils/common";
import {checkMD5, KEY_SPLIT, MESSAGE_AUTHORIZATIO_FAILED, MESSAGE_HTTP_ERROR, MESSAGE_NETWORK} from '@/api/base';

//
const a = axios.create({
    timeout: 10000
    , baseURL: process.env.NODE_ENV === "development" ? '/ycApi' : "/ycApi"
})
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// a.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//
a.interceptors.request.use(req => {
    //
    return req;
}, error => {
    //
    console.log(error);
    return Promise.reject(error);
})
//
a.interceptors.response.use(resp => {
    //
    let data;
    let arr = resp.data.split(KEY_SPLIT);
    //
    if (arr.length !== 2) {
        Toast.fail(MESSAGE_HTTP_ERROR);
        return Promise.reject(new Error(MESSAGE_HTTP_ERROR))
    }
    //
    data = CryptoJS.decrypt(arr[0]);
    if (!checkMD5(data, arr[1])) {
        Toast.fail(MESSAGE_AUTHORIZATIO_FAILED);
        return Promise.reject(new Error(MESSAGE_AUTHORIZATIO_FAILED))
    }
    //
    if (resp.status === 200&&JSON.parse(data).ResultCode==="200") {
        if(JSON.parse(data).Data.indexOf("{")!=-1){
            resp.data = JSON.parse(JSON.parse(data).Data);
        }else{
            resp.data = JSON.parse(decodeURIComponent(JSON.parse(data).Data.replace(/\+/g,' ')));
            // resolve(decodeURIComponent(responseResultByWCFService.Data.replace(/\+/g,' ')));
        }
        // resp.data = JSON.parse(JSON.parse(data).Data);
        return Promise.resolve(resp);
    } else {
        Toast.fail(resp.message);
        return Promise.reject(new Error(resp.message || 'Error'))
    }
}, error => {
    //
    if (isEmpty(error.response)) {
        Toast({
            message: MESSAGE_NETWORK,
            duration: 1500,
            forbidClick: true
        });
        return;
    }
    //
    if (error.response.status) {
        Toast({
            message: MESSAGE_HTTP_ERROR,
            duration: 1500,
            forbidClick: true
        });
    }
})

export default a


