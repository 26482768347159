import {
    initApi,
    QUERY_ORDER,
    QUERY_ORDER_INFO,
    QUERY_ORDER_PAGE,
    QUERY_ORDER_TRANSACTIONS,
    SEND_CLOSE_DEVICE_COMMAND,
    SEND_LOAN_ORIGINATION_FEE_COMMAND,
    SEND_START_DEVICE_COMMAND
} from '@/api/base';

/**
 * 订单api
 *
 * @type {{getOrder(*): *}}
 */
const order = {
    //
    getOrderPage(data) {
        let order = initApi(data);
        order.OperName = QUERY_ORDER_PAGE;
        return order;
    }
    //
    , getOrder(data) {
        let order = initApi(data);
        order.OperName = QUERY_ORDER;
        return order;
    }
    //
    , getOrderTransactions(data) {
        let order = initApi(data);
        order.OperName = QUERY_ORDER_TRANSACTIONS;
        return order;
    }
    //
    , getQueryOrderInfo(data) {
        let order = initApi(data);
        order.OperName = QUERY_ORDER_INFO;
        return order;
    }
    //
    , sendStartDeviceCommand(data) {
        let order = initApi(data);
        order.OperName = SEND_START_DEVICE_COMMAND;
        return order;
    }
    //
    , sendCloseDeviceCommand(data) {
        let order = initApi(data);
        order.OperName = SEND_CLOSE_DEVICE_COMMAND;
        return order;
    }
    //
    , sendLoanOriginationFeeCommand(data) {
        let order = initApi(data);
        order.OperName = SEND_LOAN_ORIGINATION_FEE_COMMAND;
        return order;
    }
}


export default order
