import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: 'code'
    },
    {
        name: 'pay',
        component: () => import('../views/pay'),
        meta: {
            title: '运创共享-支付'
        }
    },
    {
        name: 'code',
        component: () => import('../views/code'),
        meta: {
            title: '运创共享-扫码'
        }
    },
    {
        name: 'item',
        component: () => import('../views/item'),
        meta: {
            title: '运创共享-详情'
        }
    },
    {
        name: 'order',
        component: () => import('../views/order'),
        meta: {
            title: '运创共享-订单'
        }
    },
    {
        name: 'info',
        component: () => import('../views/info'),
        meta: {
            title: '个人信息'
        }
    },
    {
        name: 'index',
        component: () => import('../views/index'),
        meta: {
            title: '运创共享-扫码'
        }
    },
    {
        name: 'about',
        component: () => import('../views/about'),
        meta: {
            title: '运创共享-扫码'
        }
    },
];


// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});


const router = new Router({/*mode: 'history',*/ routes});


router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});


export default router

