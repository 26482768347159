import CryptoJS from "../../utils/des";
import md5 from "js-md5";
import {isEmpty} from "../../utils/common";


/********************** api msg s ************************/
export const MESSAGE_AUTHORIZATIO_FAILED = '授权失败！';
export const MESSAGE_HTTP_ERROR = '服务请求异常！';
export const MESSAGE_LOADING = '努力加载中...';
export const MESSAGE_NETWORK = '网络异常！';
/********************** api msg E ************************/

/********************** api url s ************************/
//
export const URL_ROOT = '/';
// 微信系列
const URL_WX_CODE = 'https://open.weixin.qq.com/connect/oauth2/authorize';
const URL_WX_TOKEN = '/wxApi/sns/oauth2/access_token';
const URL_WX_USER_INFO = '/wxApi/sns/userinfo';
// const URL_WX_CALL_BACK = 'http://192.168.89.206:10001/#/pay';
const URL_WX_CALL_BACK = 'http://admin.yunchuang0359.com/#/pay';
// 阿里系列
const URL_ALI_CODE = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm';
// const URL_ALI_CALL_BACK = 'http://www.yunchuang0359.com/GetZFBAuthcode.aspx';
const URL_ALI_CALL_BACK = 'http://www.yunchuang0359.com:10000/#/pay';
// 运创系列
export const URL_YC = 'http://www.yunchuang0359.com';
export const URL_YC_SERVER = URL_YC + '/ActionApi/Values/ReceptionRecord';
/********************** api url E ************************/


/********************** api request s ************************/
// 语言系列
const LANG_ZH_CN = 'zh_CN';
// 加解密系列
export const DES_KEY = 'X2E5X8SY2M';
export const DESVECTOR_KEY = 'TR86AUK37EDEHGFHBJ47GXSD5BCEVJIO';
// 微信系列
const WX_APP_ID_ = 'wx6c51dc84f2e959b3';
const WX_SECRET = 'd543ffa2524c99036c72bfae15ffff71';
// 支付宝系列
const ALI_APP_ID = '2021001190680185';
/********************** api request s ************************/


/********************** api key s ************************/
export const BASE_ACTION_NAME = 'ActionApi/Values/ReceptionRecord';
export const ACTION_NAME = 'SystemReception/ShareDAL';
export const KEY_SPLIT = '&';
/********************** api key s ************************/


/********************** api method s ************************/
// 用户查询
export const QUERY_USER = 'QueryUser';
export const QUERY_WX_USER_LOGIN = 'QueryWXUserLogin';
export const QUERY_WX_LOGIN_ACCESS_TOKEN = 'QueryWXLoginAccessToken';
export const QUERY_ALI_USER_LOGIN = 'QueryZFBUserLogin';
export const QUERY_ORDER_INFO = 'QueryOrderInfo';
export const Update_USER = 'UpdateUser';//更新用户
// 用户订单
export const QUERY_ORDER_PAGE = 'QueryOrderPage';
export const QUERY_ORDER = 'QueryOrder';
export const QUERY_ORDER_TRANSACTIONS = 'QueryOrderTransactions';
export const SEND_START_DEVICE_COMMAND = 'SendStartDeviceCommand';
export const SEND_CLOSE_DEVICE_COMMAND = 'SendCloseDeviceCommand';
export const SEND_LOAN_ORIGINATION_FEE_COMMAND = 'SendLoanOriginationFeeCommand';

// 设备查询
export const QUERY_DEVICE = 'QueryDevice';
export const QUERY_DEVICE_BY_DEVICESN = 'QueryDeviceByDeviceSN';
// 设备端口
export const QUERY_DEVICE_PORT = 'QueryDevicePort';
// 设备优惠套餐
export const QUERY_PAYMENT_PACKAGE = 'QueryPaymentPackage';
export const QUERY_CUSTOMER_PAYMENT_PACKAGE = 'QueryCustomerPaymentPackage';
// 微信支付
export const PAY_WX = 'WXOrderPayment';
export const PAY_WX_RENEWAL = 'WXOrderLoanOriginationFee';
// 支付宝支付
export const PAY_ALI = 'ZFBOrderPayment';
export const PAY_ALI_RENEWAL = 'ZFBOrderLoanOriginationFee';
//查询系统状态
export const QUERY_SYS_STATES = 'GetUnderMaintenance';
/********************** api method s ************************/


/********************** api function s ************************/
/**
 * 初始化api请求
 * @returns {{}}
 */
export function initApi(data) {
    let initObj = {};
    initObj.ActionName = ACTION_NAME;
    initObj.DevSource = '1';
    initObj.Data = data;
    // initObj.user = localStorage.getItem('USER').ID;
    // alert('user:'+initObj.user);

    if(isEmpty(localStorage.getItem('USER'))){
        // alert('USER:1')
        initObj.user = '-1';
    }else {
        // alert('USER2:'+localStorage.getItem('USER'))
        // alert('ID:'+JSON.parse(localStorage.getItem('USER')).ID);
        initObj.user = JSON.parse(localStorage.getItem('USER')).ID;
    }
    // if (isEmpty(data.userID)) {
    //     if (isEmpty(data.UserID)) {
    //         initObj.user = '';
    //     } else {
    //         initObj.user = data.UserID;
    //     }
    // } else {
    //     initObj.user = data.userID;
    // }
    initObj.Certificate = '';
    return initObj;
}

/**
 * 加密
 * @param data
 * @returns {{}}
 */
export function encrypt(data) {
    let obj = {};
    let desStr;
    let md5Str;
    // data json
    let dataJson = JSON.stringify(data);
    // des
    desStr = CryptoJS.encrypt(dataJson);
    // md5
    md5Str = md5(dataJson + DES_KEY.substring(0, 5));
    //
    obj.requestString = desStr + KEY_SPLIT + md5Str;
    //
    return obj;
}

/**
 * 校验MD5码
 * @param data
 * @param md5Str
 * @returns {boolean}
 */
export function checkMD5(data, md5Str) {
    return md5Str === md5(data + DES_KEY.substring(0, 5));

}

/** 微信 S **/
export function getWxCodeUrl(deviceSn) {
    return URL_WX_CODE + '?appid=' + WX_APP_ID_ + '&redirect_uri=' + encodeURIComponent(URL_WX_CALL_BACK + '?deviceSn=' + deviceSn) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect';
    // return URL_WX_CODE + '?appid=' + WX_APP_ID_ + '&redirect_uri=' + encodeURIComponent(window.location.href)+ '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect';
}

export function getWxTokenUrl(code) {
    return URL_WX_TOKEN + '?appid=' + WX_APP_ID_ + '&secret=' + WX_SECRET + '&code=' + code + '&grant_type=authorization_code';
}

export function getWxUserInfoUrl(token, openId) {
    return URL_WX_USER_INFO + '?access_token=' + token + '&openid=' + openId + '&lang=' + LANG_ZH_CN;
}

/** 微信 E **/

/** 阿里 S **/
export function getAliCodeUrl(deviceSn) {
    return URL_ALI_CODE + '?app_id=' + ALI_APP_ID + '&scope=auth_user&redirect_uri=' + encodeURIComponent(URL_ALI_CALL_BACK)+'&state=2'+'&deviceSn='+ deviceSn;
}

/** 阿里 E**/

/********************** api function e ************************/

