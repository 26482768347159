import {initApi, PAY_ALI, PAY_ALI_RENEWAL, PAY_WX, PAY_WX_RENEWAL} from '@/api/base';
import {Toast} from "vant";
import {isEmpty} from "../../utils/common";


const pay = {
    //
    weiXin(data) {
        let wx = initApi(data);
        wx.OperName = PAY_WX;
        wx.Data = data;
        return wx;
    }
    //
    , weiXinRenewal(data) {
        let wx = initApi(data);
        wx.OperName = PAY_WX_RENEWAL;
        wx.Data = data;
        return wx;
    }
    //
    , aLi(data) {
        let ali = initApi(data);
        ali.OperName = PAY_ALI;
        ali.Data = data;
        return ali;
    }
    //
    , aLiRenewal(data) {
        let ali = initApi(data);
        ali.OperName = PAY_ALI_RENEWAL;
        ali.Data = data;
        return ali;
    }
    //
    , sendALi(data) {
        let ali = {};
        ali.ordersn = '123456654321';
        ali.total_fee = 0.01;
        ali.subject = 'imfyc-subject';
        ali.body = 'imfyc-body';
        return ali;
    }
    //
    , sendWinXin(data) {
        let discount = initApi(data);
        discount.OperName = PAY_WX;
        // userID不需要
        data.userID = undefined;
        discount.Data = data;
        return discount;
    }

    /**
     * 调用支付
     *
     * @param user
     * @param device
     * @param curPort
     * @param curDiscount
     * @returns {{}}
     */
    , initPayParams(user, device, curPort, curDiscount) {
        let wxPay = {};
        // 用户系列：正常
        wxPay.UserID = user.ID;
        // 设备系列
        wxPay.DeviceSN = device.DeviceSN;
        wxPay.PlatformIncomeRatio = device.PlatformIncomeRatio;
        wxPay.MerchantsID = device.MerchantsID;
        wxPay.MerchantsIncomeRatio = device.MerchantsIncomeRatio;
        wxPay.AgentID = device.AgentID;
        wxPay.AgentIncomeRatio = device.AgentIncomeRatio;
        // 端口系列
        wxPay.PortSn = isEmpty(curPort.PortSn) ? curPort : curPort.PortSn;
        // 套餐系列
        wxPay.PaymentPackageID = curDiscount.ID;
        wxPay.TransactionPrice = curDiscount.Price;
        // // 1微信公众号，2微信小程序，3支付宝
        // wxPay.PaymentType = 1;
        // 1正常，2续费
        wxPay.OrderType = 1;
        //
        return wxPay;
    }

    , wxPay(data, that) {
        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.wxJsApiCall(data, that), false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.wxJsApiCall(data, that));
                document.attachEvent('onWeixinJSBridgeReady', this.wxJsApiCall(data, that));
            }
        } else {
            this.wxJsApiCall(data, that);
        }
    }

    , wxJsApiCall(data, that) {
        //
        let tmp = JSON.parse(data);
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId": tmp.appId,     //公众号名称，由商户传入
                "timeStamp": tmp.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr": tmp.nonceStr,  //随机串
                "package": tmp.package,
                "signType": tmp.signType,         //微信签名方式：
                "paySign": tmp.paySign //微信签名
            },
            function (res) {
                //
                if (res.err_msg === 'get_brand_wcpay_request:cancel') {
                    return;
                }
                //
                if (res.err_msg === 'get_brand_wcpay_request:ok') {
                    // window.location.reload = 'http://admin.yunchuang0359.com:10000/#/item';
                    // alert('user:'+sessionStorage.getItem('USER'))
                    that.$router.push({name: 'item',params: {userId: this.userId}})
                    return;
                }
                //
                Toast.fail('下单失败');
            });
    }

}


export default pay
