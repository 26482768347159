import api from './api'
import user from '@/api/user/'
import order from '@/api/order/'
import device from '@/api/device/'
import discount from '@/api/discount/'
import pay from '@/api/pay/'
import wx from '@/api/wx/'


export default {
    api, user, order, device, discount, pay, wx
}

