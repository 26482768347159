/**
 * 常用大本营.
 *
 * @author imfyc.
 * @data 2020/8/5.
 */

import {Toast} from "vant";
import {MESSAGE_LOADING} from "../api/base";

/** check S **/
export function isEmpty(val) {
    if (null === val || '' === val || undefined === val || 'undefined' === val || '{}' === val || 'null' === val) {
        return true;
    }
}

export function isNotEmpty(val) {
    return !isEmpty(val);
}

export function checkNumberIsNull(val) {
    if (isEmpty(val) || val < 0 || val === '0' || val === '0.0' || val === '0.00') {
        return '-';
    }
    return val;
}

export function handleReturnCode(msg) {
    let msgNew = isEmpty(msg) ? '请扫码重试！' : msg;
    this.$toast.fail(msgNew);
    this.$router.push({
        name: 'code'
        , params: {
            errMsg: msgNew
        }
    })
}

/** check S **/

/**
 *
 * @param source 1微信，2支付宝
 * @returns {*}
 */
export function getCallbackReq() {
    // 微信回调，获取code的方法
    var url = decodeURI(location.search); //获取url中"?"符后的字串
    var req = {};
    var str;
    var strs;
    var devicesn;
    //
    if (isNotEmpty(url)) {
        if (url.indexOf("?") != -1) {
            str = url.substr(1);
            strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                req[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return req;
    }
    // 人为拼接的参数
    url = location.href;
    strs = url.split('?');
    if (isEmpty(strs) || strs.length !== 3) {
        return req;
    }
    //获取deviceSn
    req.deviceSn = strs[2].split('#')[0].split('=')[1];
    //
    strs = strs[1].split('&');
    // 微信拼接的参数
    req.code = strs[0].split('=')[1];
    return req;
}

export function getSN() {
    let url = location.href;
    let strs = url.split('?');
    if (strs.length === 2) {
        strs = strs[1].split('=');
        return strs[1];
    }
}

export function getCallBackReq1() {
    var url = decodeURI(window.document.location.href.toString()); //获取的完整url
    // alert('url:'+url)
    var u = url.split("?");
    if (typeof (u[1]) == "string") {
        u = u[1].split("&");
        var get = {};
        for (var i in u) {
            var j = u[i].split("=");
            get[j[0]] = j[1];
        }
        return get;
    } else {
        return {};
    }
}

export function handleCountDownTime(endTime) {
    let m;
    let tmp = endTime.replace(new RegExp(/-/gm), "/");
    let date = new Date(tmp);
    let nowDate = new Date();
    m = date.getTime() - nowDate.getTime();
    return m > 0 ? m : 0;
}

export function handleLoading(toastObj) {
    toastObj = Toast.loading({
        message: MESSAGE_LOADING,
        forbidClick: true,
    });
    return toastObj;
}

export function handleZero(str) {
    return str.replace(/\b(0+)/gi, "");
}

