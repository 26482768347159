import axios from '@/axios'
import qs from 'qs';
import {encrypt} from '@/api/base';


const api = {
    req(params) {
        return axios.post("/", qs.stringify(encrypt(params)))
    }
}

export default api
