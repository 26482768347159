import {initApi, QUERY_ALI_USER_LOGIN, QUERY_USER, QUERY_WX_LOGIN_ACCESS_TOKEN, QUERY_WX_USER_LOGIN} from '@/api/base';
import {Update_USER} from "../base";

/**
 * 用户api
 *
 * @type {{getUser(): {}}}
 */
const user = {
    //
    getUser(data) {
        let user = initApi(data);
        user.OperName = QUERY_USER;
        return user;
    }

    //
    , getWxUserLogin(data) {
        let user = initApi(data);
        user.OperName = QUERY_WX_USER_LOGIN;
        return user;
    }

    //
    , getUserToken(data) {
        let user = initApi(data);
        user.OperName = QUERY_WX_LOGIN_ACCESS_TOKEN;
        return user;
    }

    //
    , getAliUserLogin(data) {
        let user = initApi(data);
        user.OperName = QUERY_ALI_USER_LOGIN;
        return user;
    }
    //
    , updateUser(data){
        let user = initApi(data);
        user.OperName = Update_USER;
        return user;
    }
}


export default user
