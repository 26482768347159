import {initApi, QUERY_CUSTOMER_PAYMENT_PACKAGE, QUERY_PAYMENT_PACKAGE} from '@/api/base';


const discount = {
    //
    getDiscount(data) {
        let discount = initApi(data);
        discount.OperName = QUERY_PAYMENT_PACKAGE;
        // userID不需要
        data.userID = undefined;
        discount.Data = data;
        return discount;
    }
    //
    , getDiscountCustomer(data) {
        let discount = initApi(data);
        discount.OperName = QUERY_CUSTOMER_PAYMENT_PACKAGE;
        // userID不需要
        data.userID = undefined;
        discount.Data = data;
        return discount;
    }
}


export default discount
