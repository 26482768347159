import {getWxTokenUrl, getWxUserInfoUrl} from "../base";
import axios from "axios";
import {isEmpty} from "../../utils/common";

/**
 * 获取微信用户信息
 *
 * @type {{getUserInfo(*): {}}}
 */
const wx = {
    async getUserInfo(code) {
        let userInfoObj = {};
        await axios.get(getWxTokenUrl(code))
            .then(resp => {
                //
                if (isEmpty(resp) || isEmpty(resp.data)) {
                    userInfoObj.msg = '微信令牌获取失败！';
                    return;
                }
                //
                axios.get(getWxUserInfoUrl(resp.data.access_token, resp.data.openid))
                    .then(resp1 => {
                        //
                        if (isEmpty(resp1) || isEmpty(resp1.data)) {
                            userInfoObj.msg = '微信用户信息失败！';
                            return;
                        }
                        //
                        userInfoObj.data = JSON.stringify(resp1.data);
                    }).catch(function (error) {
                    userInfoObj.msg = '微信用户信息异常！';
                });
            }).catch(function (error) {
                userInfoObj.msg = '微信令牌获取异常！';
            });
        //
        return userInfoObj;
    }
}

export default wx
