import {initApi, QUERY_DEVICE, QUERY_DEVICE_BY_DEVICESN, QUERY_DEVICE_PORT,QUERY_SYS_STATES} from '@/api/base';

/**
 * 设备api
 *
 * @type {{getDevicePort(*): *}}
 */
const user = {
    //
    getDevice(data) {
        let devicePort = initApi(data);
        devicePort.OperName = QUERY_DEVICE;
        return devicePort;
    }
    //
    , getDeviceBySn(data) {
        let devicePort = initApi(data);
        devicePort.OperName = QUERY_DEVICE_BY_DEVICESN;
        return devicePort;
    }
    //
    , getDevicePort(data) {
        let devicePort = initApi(data);
        devicePort.OperName = QUERY_DEVICE_PORT;
        return devicePort;
    }
    , getSysStates(data) {
        let sysStates = initApi(data);
        sysStates.OperName = QUERY_SYS_STATES;
        return sysStates;
    }
}


export default user
