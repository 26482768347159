import CryptoJS from "crypto-js";
import {DES_KEY, DESVECTOR_KEY} from '@/api/base';

export default {

    encrypt(data) {
        //
        let encrypted = CryptoJS.DES.encrypt(CryptoJS.enc.Utf8.parse(data), getDesKey(), {
            iv: getDesvectorKey(),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        //
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    , decrypt(message) {
        //
        var decrypt = CryptoJS.DES.decrypt(message, getDesKey(), {
            iv: getDesvectorKey(),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        //
        return decrypt.toString(CryptoJS.enc.Utf8);
    }
};

function getDesKey() {
    return CryptoJS.enc.Utf8.parse(DES_KEY.substr(0, 8));
}

function getDesvectorKey() {
    return CryptoJS.enc.Utf8.parse(DESVECTOR_KEY.substring(DESVECTOR_KEY.length - 8, DESVECTOR_KEY.length));
}


